import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "result"];

  static values = {
    choices: Array,
    result: { type: Number, default: null },
  };

  connect() {
    this.selectTargets.forEach((select) => {
      select.addEventListener("change", () => this.calculateGeometricMean());
    });
    this.calculateGeometricMean();
  }

  resultValueChanged() {
    if (this.resultValue === null || isNaN(this.resultValue)) {
      this.resultTarget.textContent = "~";
    } else {
      this.resultTarget.textContent = this.resultValue.toFixed(2);
    }
  }

  calculateGeometricMean() {
    const { selectedValues, allSelected, hasZero } = this.collectValues();

    if (!allSelected) {
      this.resultValue = null;
      return;
    }

    if (hasZero) {
      this.resultValue = 0;
      return;
    }

    const n = selectedValues.length;
    const product = selectedValues.reduce((acc, val) => acc * val, 1);
    this.resultValue = Math.pow(product, 1 / n);
  }

  collectValues() {
    const selectedValues = [];
    let hasZero = false;

    for (let select of this.selectTargets) {
      if (!select.value) {
        return { selectedValues: [], allSelected: false, hasZero: false };
      }

      const choice = this.choicesValue.find((c) => c.label === select.value);
      const numericValue = parseFloat(choice.value);

      if (numericValue === 0) {
        hasZero = true;
      }
      selectedValues.push(numericValue);
    }

    return { selectedValues, allSelected: true, hasZero };
  }
}
