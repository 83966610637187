import { Controller } from "@hotwired/stimulus";

/**
 * When the enable action is triggered, enable the target input,and hide the
 * triggering element.
 */
export default class extends Controller {
  static targets = ["input"];

  enable(event) {
    event.preventDefault();

    const trigger = event.target;
    trigger.classList.add("d-none");

    this.inputTarget.disabled = false;
    this.inputTarget.focus();
  }
}
