import { formatBarChartLabel, formatDonutLegendLabel } from "./formatters";

export function areaChartOptions(data) {
  const { height, labels, toolbar, values, yAxisLabel } = data;

  return {
    chart: {
      height,
      toolbar,
      type: "area",
      width: "100%",
    },
    dataLabels: {
      enabled: false,
    },
    labels: labels,
    series: [
      {
        name: yAxisLabel,
        data: values,
      },
    ],
    stroke: {
      curve: "straight",
    },
    yaxis: {
      decimalsInFloat: 1,
      title: {
        text: yAxisLabel,
      },
    },
  };
}

export function barChartOptions(data) {
  const { colors, dataFormat, height, labels, legend, series, toolbar } = data;

  const options = {
    chart: {
      height,
      toolbar,
      type: "bar",
      width: "100%",
    },
    colors,
    dataLabels: {
      offsetX: -10,
      textAnchor: "end",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        distributed: colors && colors.length > 1,
      },
    },
    xaxis: {
      categories: labels.map((label) => formatBarChartLabel(label, 60)),
      forceNiceScale: true,
    },
    yaxis: {
      labels: {
        minWidth: 120,
        maxWidth: 400,
      },
    },
    series,
  };

  if (dataFormat === "percent") {
    options.dataLabels.formatter = (val) => `${val}%`;
    options.xaxis.labels = { formatter: (val) => `${val}%` };
  }

  if (legend === false) {
    options.legend = { show: false };
  }

  return options;
}

export function columnChartOptions(data) {
  const { height, labels, series, toolbar } = data;

  let breakpoint;
  const labelCount = labels.length;
  if (labelCount > 5) {
    breakpoint = 10;
  } else if (labelCount === 4) {
    breakpoint = 20;
  } else {
    breakpoint = 25;
  }

  return {
    chart: {
      height,
      toolbar,
      type: "bar",
      width: "100%",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
        },
      },
    },
    xaxis: {
      categories: labels.map((label) => formatBarChartLabel(label, breakpoint)),
      labels: {
        minHeight: 120,
        maxHeight: 400,
      },
    },
    series,
  };
}

export function donutChartOptions(data) {
  const { height, labels, toolbar, values } = data;

  return {
    chart: {
      height,
      toolbar,
      type: "donut",
      width: "100%",
    },
    labels: labels,
    legend: {
      formatter: formatDonutLegendLabel.bind(null, data),
      fontSize: "12px",
      itemMargin: {
        horizontal: 3,
      },
      offsetY: -5,
      position: "bottom",
    },
    series: values,
  };
}
